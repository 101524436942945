import axios from 'axios';
import React from 'react';
import { Accordion, Col, Row, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { FormDescription, FormImage } from '../../../components/form';

const AdditionalItems = ({ additionals, setAdditionals, slug, type }) => {
      const handleChange = (index, e) => {
            const values = [...additionals];
            values[index][e.target.name] = e.target.value;

            setAdditionals(values);
      };

      const handleAddFields = () => {
            setAdditionals([
                  ...additionals,
                  { title: '', description: '', image: '', imageUrl: '' }
            ]);
      };

      const handleRemoveFields = async (index) => {
            const values = [...additionals];
            if (type) {
                  await axios
                        .post(
                              `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/products/${slug}/remove`,
                              { image: index },
                              {
                                    headers: {
                                          Authorization: `Bearer ${JSON.parse(
                                                localStorage.getItem('token')
                                          )}`,
                                          'Content-Type': 'multipart/form-data'
                                    }
                              }
                        )
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    toast.success(response.data.message);
                                    values.splice(index, 1);
                                    setAdditionals(values);
                              }
                        })
                        .catch((error) => {
                              toast.error(error.message);
                        });
            } else {
                  values.splice(index, 1);
                  setAdditionals(values);
            }
      };

      return (
            <Accordion className="mt-4">
                  <Accordion.Item eventKey="2">
                        <Accordion.Header>Additional Details</Accordion.Header>
                        <Accordion.Body>
                              {additionals.map((field, index) => (
                                    <Row key={index} className="g-3">
                                          <Col lg={12}>
                                                <Form.Label>Title</Form.Label>
                                                <Form.Control
                                                      type="text"
                                                      name="title"
                                                      value={field.title}
                                                      onChange={(e) =>
                                                            handleChange(
                                                                  index,
                                                                  e
                                                            )
                                                      }
                                                />
                                          </Col>
                                          <Col lg={12}>
                                                <FormDescription
                                                      title="Description"
                                                      description={
                                                            field.description
                                                      }
                                                      setDescription={(url) =>
                                                            handleChange(
                                                                  index,
                                                                  {
                                                                        target: {
                                                                              name: 'description',
                                                                              value: url
                                                                        }
                                                                  }
                                                            )
                                                      }
                                                />
                                          </Col>
                                          <Col lg={12}>
                                                <FormImage
                                                      name={`image${index}`}
                                                      label="Image"
                                                      imageUrl={field.imageUrl}
                                                      setImage={(url) =>
                                                            handleChange(
                                                                  index,
                                                                  {
                                                                        target: {
                                                                              name: 'image',
                                                                              value: url
                                                                        }
                                                                  }
                                                            )
                                                      }
                                                      setImageUrl={(url) =>
                                                            handleChange(
                                                                  index,
                                                                  {
                                                                        target: {
                                                                              name: 'imageUrl',
                                                                              value: url
                                                                        }
                                                                  }
                                                            )
                                                      }
                                                />
                                          </Col>
                                          <Col lg={12}>
                                                <button
                                                      type="button"
                                                      className="btn btn-danger"
                                                      onClick={() =>
                                                            handleRemoveFields(
                                                                  index
                                                            )
                                                      }
                                                >
                                                      Remove
                                                </button>
                                          </Col>
                                    </Row>
                              ))}
                              <button
                                    type="button"
                                    className="btn btn-secondary mt-4"
                                    onClick={handleAddFields}
                              >
                                    Add Fields
                              </button>
                        </Accordion.Body>
                  </Accordion.Item>
            </Accordion>
      );
};

export default AdditionalItems;
